import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')];

export const server_loads = [0];

export const dictionary = {
	"/": [~4],
	"/_dev/ui/auth": [7,[3]],
	"/(webflow)/about-new": [~5,[2]],
	"/billing": [~8],
	"/dash": [~9],
	"/dev-access": [10],
	"/e/[eventId]": [~11],
	"/maintenance": [12],
	"/new": [13],
	"/new/[...initial]": [14],
	"/(webflow)/pricing": [~6,[2]],
	"/support": [16],
	"/s/[id]": [~15],
	"/u": [17]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};